import { config } from '@fortawesome/fontawesome-svg-core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import type { ReactElement } from 'react';

import { analytics, AnalyticsProvider } from '@/lib/analytics';
import { getConfiguration } from '@/lib/datocms/api';
import { ConfigurationContext } from '@/lib/contexts/configuration-context';
import { Configuration } from '@/lib/types';

import '@/styles/main.scss';

// Prevent Font Awesome from dynamically injecting CSS as we're doing it manually to prevent SSR rendering issues
config.autoAddCss = false;

function RACTApp({ Component, pageProps }: AppProps): ReactElement {
  const router = useRouter();
  const [configuration, setConfiguration]: [
    Configuration | undefined,
    Dispatch<SetStateAction<Configuration | undefined>>
  ] = useState();

  useEffect(() => {
    const handleRouteChange = () => {
      analytics.page({
        event: 'Page Viewed',
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function fetchConfiguration() {
      setConfiguration(await (await getConfiguration())?.configuration);
    })();
  }, []);

  return (
    <ConfigurationContext.Provider value={configuration}>
      <AnalyticsProvider instance={analytics}>
        <Component {...pageProps} />
      </AnalyticsProvider>
    </ConfigurationContext.Provider>
  );
}

export default RACTApp;
