const RESPONSIVE_IMAGE_FRAGMENT_NO_BASE64 = `
  aspectRatio
  height
  sizes
  src
  srcSet
  webpSrcSet
  width
  alt
  title
`;

const RESPONSIVE_IMAGE_FRAGMENT = `
  ${RESPONSIVE_IMAGE_FRAGMENT_NO_BASE64}
  base64
`;

const FOCAL_POINT_FRAGMENT = `
  focalPoint {
    x
    y
  }
`;

type ImageQueryType = {
  fit?: string;
  h: number;
  w: number;
  hasAuthor?: boolean;
  hasFocalPoint?: boolean;
  name?: string;
};

const getResponsiveImageFragment = ({
  fit = 'crop',
  h,
  w,
  hasAuthor = false,
  hasFocalPoint = false,
  name = 'data',
}: ImageQueryType): string => {
  return `
    ${name}: responsiveImage(imgixParams: { fit: ${fit}, w: ${w}, h: ${h}, auto: format }) {
      ${RESPONSIVE_IMAGE_FRAGMENT}
    }
    ${hasFocalPoint ? FOCAL_POINT_FRAGMENT : ''}
    ${hasAuthor ? 'author' : ''}
  `;
};

const JOURNEY_LINK_FRAGMENT = `
  type: _modelApiKey
  id
  slug
  tag {
    slug
  }
`;

const PAGE_LINK_FRAGMENT = `
  type: _modelApiKey
  id
  slug
  parent {
    slug
    parent {
      slug
      parent {
        slug
        parent {
          slug
        }
      }
    }
  }
`;

const ARTICLE_LINK_FRAGMENT = `
  type: _modelApiKey
  id
  slug
  categoryPage {
    ${PAGE_LINK_FRAGMENT}
  }
`;

const REWARD_LINK_FRAGMENT = `
  type: _modelApiKey
  id
  slug
  categoryPage {
    ${PAGE_LINK_FRAGMENT}
  }
`;

const TAG_LINK_FRAGMENT = `
  type: _modelApiKey
  id
  slug
`;

const HOME_PAGE_LINK_FRAGMENT = `
  type: _modelApiKey
  id
`;

const JOURNEY_LANDING_PAGE_LINK_FRAGMENT = `
  type: _modelApiKey
  id
`;

const LINK_FRAGMENT = `
  ... on ArticleRecord {
    ${ARTICLE_LINK_FRAGMENT}
  }
  ... on ExternalLinkRecord {
    type: _modelApiKey
    id
    uri
  }
  ... on FileRecord {
    type: _modelApiKey
    id
    file {
      url
    }
  }
  ... on JourneyRecord {
    ${JOURNEY_LINK_FRAGMENT}
  }
  ... on PageRecord {
    ${PAGE_LINK_FRAGMENT}
  }
  ... on RewardRecord {
    ${REWARD_LINK_FRAGMENT}
  }
  ... on HomePageRecord {
    ${HOME_PAGE_LINK_FRAGMENT}
  }
  ... on JourneyLandingPageRecord {
    ${JOURNEY_LANDING_PAGE_LINK_FRAGMENT}
  }
`;

const CTA_LINK_FRAGMENT = `
  id
  type: _modelApiKey
  anchor
  linkText
  link {
    ${LINK_FRAGMENT}
  }
`;

const IMAGE_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  images {
    width
    height
    ${getResponsiveImageFragment({ fit: 'min', w: 680, h: 930, name: 'portrait' })}
    ${getResponsiveImageFragment({
      fit: 'min',
      w: 930,
      h: 630,
      name: 'landscape',
      hasAuthor: true,
      hasFocalPoint: true,
    })}
  }
`;

const VIDEO_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  video {
    height
    provider
    providerUid
    thumbnailUrl
    title
    url
    width
  }
  anchor
`;

const WIDGET_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  widget
  anchor
`;

const ACCORDION_DATA_ITEM_FRAGMENT = `
  id
  type: _modelApiKey
  title
  content {
    value
    blocks {
      ... on ImageBlockRecord {
        ${IMAGE_BLOCK_FRAGMENT}
      }
      ... on VideoBlockRecord {
        ${VIDEO_BLOCK_FRAGMENT}
      }
    }
    links {
      ${LINK_FRAGMENT}
    }
  }
`;

const ACCORDION_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  items {
    ${ACCORDION_DATA_ITEM_FRAGMENT}
  }
  theme
  anchor
`;

const BANNER_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  description
  primaryLinkText
  secondaryLinkText
  variant
  imageTint
  contentPosition
  bannerHeight
  bannerAlignment
  image {
    ${getResponsiveImageFragment({ w: 1366, h: 640, hasFocalPoint: true })}
  }
  primaryLink {
    ${LINK_FRAGMENT}
  }
  secondaryLink {
    ${LINK_FRAGMENT}
  }
  anchor
`;

const CTA_ITEM_FRAGMENT = `
  id
  type: _modelApiKey
  description
  linkText
  icon
  link {
    ${LINK_FRAGMENT}
  }
`;

const CTA_GRID_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  layout
  items {
    ${CTA_ITEM_FRAGMENT}
  }
  anchor
`;

const CTA_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  theme
  layout
  linkText
  link {
    ${LINK_FRAGMENT}
  }
  prefixContent {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
  suffixContent {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
  anchor
`;

const FEATURE_ITEM_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  description
  layout
  linkText
  link {
    ${LINK_FRAGMENT}
  }
  image {
    ${getResponsiveImageFragment({ w: 670, h: 500 })}
  }
  video {
    height
    provider
    providerUid
    thumbnailUrl
    title
    url
    width
  }
  shouldHideBackground
  anchor
`;

const FEATURE_CONTACT_US_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  description
  layout
  linkText
  image {
    ${getResponsiveImageFragment({ w: 670, h: 500 })}
  }
  video {
    height
    provider
    providerUid
    thumbnailUrl
    title
    url
    width
  }
  shouldHideBackground
  anchor
`;

const FORM_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  name
  anchor
`;

const HTML_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  html
`;

const TAG_FRAGMENT = `
  type: _modelApiKey
  id
  title
  slug
  colour
`;

const PAGE_BASE_FRAGMENT = `
  id
  type: _modelApiKey
  slug
  title
  parent {
    title
    slug
    id
    parent {
      title
      slug
      parent {
        title
        slug
        parent {
          title
          slug
        }
      }
    }
  }
`;

const ARTICLE_BASE_FRAGMENT = `
  id
  type: _modelApiKey
  slug
  title
  tag {
    ${TAG_FRAGMENT}
  }
  categoryPage {
    ... on PageRecord {
      ${PAGE_BASE_FRAGMENT}
    }
  }
  publishedAt: _firstPublishedAt
`;

export const ARTICLE_CARD_FRAGMENT = `
  ${ARTICLE_BASE_FRAGMENT}
  heroImage {
    ${getResponsiveImageFragment({ w: 750, h: 460, hasFocalPoint: true })}
  }
`;

const REWARD_BASE_FRAGMENT = `
  id
  type: _modelApiKey
  slug
  title
  description
  icon
  categoryPage {
    ... on PageRecord {
      ${PAGE_BASE_FRAGMENT}
    }
  }
  publishedAt: _firstPublishedAt
`;

const JOURNEY_BASE_FRAGMENT = `
  id
  type: _modelApiKey
  title
  slug
  lead
  tag {
    ${TAG_FRAGMENT}
  }
  publishedAt: _firstPublishedAt
`;

const JOURNEY_CARD_FRAGMENT = `
  ${JOURNEY_BASE_FRAGMENT}
  heroImage {
    ${getResponsiveImageFragment({ w: 1250, h: 450, hasFocalPoint: true })}
  }
`;

const LINK_LIST_ICON_ITEM_FRAGMENT = `
  id
  type: _modelApiKey
  title
  description
  icon
  iconColour
  link {
    ${LINK_FRAGMENT}
  }
`;

const LINK_LIST_IMAGE_FRAGMENT = `
  heroImage {
    ${getResponsiveImageFragment({ w: 494, h: 299, hasFocalPoint: true })}
  }
`;

const LINK_LIST_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  description {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
  contentPosition
  layout
  theme
  items {
    ... on LinkListIconItemRecord {
      ${LINK_LIST_ICON_ITEM_FRAGMENT}
    }
    ... on ArticleRecord {
      ${ARTICLE_BASE_FRAGMENT}
      ${LINK_LIST_IMAGE_FRAGMENT}
    }
    ... on RewardRecord {
      ${REWARD_BASE_FRAGMENT}
      ${LINK_LIST_IMAGE_FRAGMENT}
    }
    ... on JourneyRecord {
      ${JOURNEY_BASE_FRAGMENT}
      ${LINK_LIST_IMAGE_FRAGMENT}
    }
    ... on PageRecord {
      ${PAGE_BASE_FRAGMENT}
      ${LINK_LIST_IMAGE_FRAGMENT}
    }
    ... on HomePageRecord {
      type: _modelApiKey
      id
      title
      ${LINK_LIST_IMAGE_FRAGMENT}
    }
    ... on JourneyLandingPageRecord {
      type: _modelApiKey
      id
      title
      ${LINK_LIST_IMAGE_FRAGMENT}
    }
  }
  anchor
`;

const NOTICE_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  content {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
  anchor
`;

const PROMO_TILE_IMAGE_FRAGMENT = `
  heroImage {
    ${getResponsiveImageFragment({ w: 250, h: 150, hasFocalPoint: true })}
  }
`;

const PROMO_TILE_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  linkText
  theme
  link {
    ${LINK_FRAGMENT}
  }
  items {
    ... on ArticleRecord {
      ${ARTICLE_BASE_FRAGMENT}
      ${PROMO_TILE_IMAGE_FRAGMENT}
    }
    ... on JourneyRecord {
      ${JOURNEY_BASE_FRAGMENT}
      ${PROMO_TILE_IMAGE_FRAGMENT}
    }
    ... on PageRecord {
      ${PAGE_BASE_FRAGMENT}
      ${PROMO_TILE_IMAGE_FRAGMENT}
    }
    ... on RewardRecord {
      ${REWARD_BASE_FRAGMENT}
      ${PROMO_TILE_IMAGE_FRAGMENT}
    }
  }
  anchor
`;

const TEXT_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  theme
  anchor
  content {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
`;

const TOP_TASK_ITEMS_FRAGMENT = `
  id
  type: _modelApiKey
  title
  icon
  link {
    ${LINK_FRAGMENT}
  }
`;

const TOP_TASKS_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  items {
    ${TOP_TASK_ITEMS_FRAGMENT}
  }
`;

const PET_INSURANCE_BLOCK_FRAGMENT = `
  id
  type: _modelApiKey
  title
  petType
`;

const CONTENT_BLOCKS_FRAGMENT = `
  ... on AccordionBlockRecord {
    ${ACCORDION_BLOCK_FRAGMENT}
  }
  ... on BannerBlockRecord {
    ${BANNER_BLOCK_FRAGMENT}
  }
  ... on CtaGridBlockRecord {
    ${CTA_GRID_BLOCK_FRAGMENT}
  }
  ... on CtaBlockRecord {
    ${CTA_BLOCK_FRAGMENT}
  }
  ... on FeatureItemBlockRecord {
    ${FEATURE_ITEM_BLOCK_FRAGMENT}
  }
  ... on FeatureContactUsBlockRecord {
    ${FEATURE_CONTACT_US_BLOCK_FRAGMENT}
  }
  ... on FormBlockRecord {
    ${FORM_BLOCK_FRAGMENT}
  }
  ... on HtmlBlockRecord {
    ${HTML_BLOCK_FRAGMENT}
  }
  ... on ImageBlockRecord {
    ${IMAGE_BLOCK_FRAGMENT}
  }
  ... on LinkListBlockRecord {
    ${LINK_LIST_BLOCK_FRAGMENT}
  }
  ... on NoticeBlockRecord {
    ${NOTICE_BLOCK_FRAGMENT}
  }
  ... on PromoTileBlockRecord {
    ${PROMO_TILE_BLOCK_FRAGMENT}
  }
  ... on TextBlockRecord {
    ${TEXT_BLOCK_FRAGMENT}
  }
  ... on TopTasksBlockRecord {
    ${TOP_TASKS_BLOCK_FRAGMENT}
  }
  ... on VideoBlockRecord {
    ${VIDEO_BLOCK_FRAGMENT}
  }
  ... on WidgetBlockRecord {
    ${WIDGET_BLOCK_FRAGMENT}
  }
  ... on PetInsuranceBlockRecord {
    ${PET_INSURANCE_BLOCK_FRAGMENT}
  }
`;

const META_TAGS_FRAGMENT = `
  metaTags: _seoMetaTags {
    attributes
    content
    tag
  }
`;

const PAGE_FULL_FRAGMENT = `
  ${PAGE_BASE_FRAGMENT}
  subtitle
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 640, hasFocalPoint: true })}
  }
  features
  ctaButtons {
    ${CTA_LINK_FRAGMENT}
  }
  contentLinks {
    ${CTA_LINK_FRAGMENT}
  }
  content {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  ${META_TAGS_FRAGMENT}
`;

const ARTICLE_FULL_FRAGMENT = `
  ${ARTICLE_BASE_FRAGMENT}
  lead
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 640, hasFocalPoint: true })}
  }
  content {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  relatedContent {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  relatedArticles {
    ${ARTICLE_CARD_FRAGMENT}
  }
  ${META_TAGS_FRAGMENT}
`;

const REWARD_FULL_FRAGMENT = `
  ${REWARD_BASE_FRAGMENT}
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 640, hasFocalPoint: true })}
  }
  partnerUrl
  content {
    value
    blocks {
      ... on ImageBlockRecord {
        ${IMAGE_BLOCK_FRAGMENT}
      }
      ... on VideoBlockRecord {
        ${VIDEO_BLOCK_FRAGMENT}
      }
      ... on WidgetBlockRecord {
        ${WIDGET_BLOCK_FRAGMENT}
      }
    }
    links {
      ${LINK_FRAGMENT}
    }
  }
  terms {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
  relatedContent {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  ${META_TAGS_FRAGMENT}
`;

const JOURNEY_FULL_FRAGMENT = `
  ${JOURNEY_BASE_FRAGMENT}
  author
  photographer
  content {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  relatedContent {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  relatedJourneys {
    ${JOURNEY_BASE_FRAGMENT}
    ${LINK_LIST_IMAGE_FRAGMENT}
  }
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 640, hasFocalPoint: true, hasAuthor: true })}
  }
  ${META_TAGS_FRAGMENT}
`;

const JOURNEY_LANDING_PAGE_FULL_FRAGMENT = `
  id
  title
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 850, hasFocalPoint: true })}
  }
  editorsChoiceJourneys {
    ${JOURNEY_CARD_FRAGMENT}
  }
  featuredJourney {
    ${JOURNEY_BASE_FRAGMENT}
  }
  tags {
    id
    slug
  }
  content {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  ${META_TAGS_FRAGMENT}
`;

const HOME_PAGE_FULL_FRAGMENT = `
  content {
    ${CONTENT_BLOCKS_FRAGMENT}
  }
  ${META_TAGS_FRAGMENT}
`;

const TAG_FULL_FRAGMENT = `
  ${TAG_FRAGMENT}
  subtitle
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 420 })}
  }
  ${META_TAGS_FRAGMENT}
`;

const RELATED_ARTICLE_LANDING_PAGE_FRAGMENT = `
  ${PAGE_BASE_FRAGMENT}
  heroImage {
    ${getResponsiveImageFragment({ w: 1366, h: 640, hasFocalPoint: true })}
  }
  ${META_TAGS_FRAGMENT}
`;

const CONFIGURATION_FULL_FRAGMENT = `
  announcementContent {
    value
    links {
      ${LINK_FRAGMENT}
    }
  }
  announcementStartTime
  announcementEndTime
`;

export {
  ARTICLE_FULL_FRAGMENT,
  ARTICLE_LINK_FRAGMENT,
  CONFIGURATION_FULL_FRAGMENT,
  HOME_PAGE_FULL_FRAGMENT,
  HOME_PAGE_LINK_FRAGMENT,
  JOURNEY_BASE_FRAGMENT,
  JOURNEY_CARD_FRAGMENT,
  JOURNEY_FULL_FRAGMENT,
  JOURNEY_LANDING_PAGE_FULL_FRAGMENT,
  JOURNEY_LANDING_PAGE_LINK_FRAGMENT,
  JOURNEY_LINK_FRAGMENT,
  META_TAGS_FRAGMENT,
  PAGE_FULL_FRAGMENT,
  PAGE_LINK_FRAGMENT,
  RELATED_ARTICLE_LANDING_PAGE_FRAGMENT,
  REWARD_FULL_FRAGMENT,
  REWARD_LINK_FRAGMENT,
  TAG_FULL_FRAGMENT,
  TAG_LINK_FRAGMENT,
};
