import { compact, isEmpty } from 'lodash';

import { captureException, captureMessage } from '@/utils/sentry';
import { fetcher } from '@/utils/fetcher';

export type RequestParams = {
  query: string;
  variables?: unknown;
};

const DATOCMS_ENV = process.env.NEXT_PUBLIC_DATOCMS_ENV;
const DATOCMS_PREVIEW_ENABLED = process.env.NEXT_PUBLIC_DATOCMS_PREVIEW_ENABLED === 'true';
const DATOCMS_TOKEN = DATOCMS_PREVIEW_ENABLED
  ? process.env.NEXT_PUBLIC_DATOCMS_PREVIEW_API_TOKEN
  : process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN;

if (isEmpty(DATOCMS_TOKEN)) {
  captureMessage('Missing DatoCMS token', { extra: { previewEnabled: DATOCMS_PREVIEW_ENABLED } });
}

// Construct API endpoint with above variables
const API_ENDPOINT = compact([
  'https://graphql.datocms.com',
  !isEmpty(DATOCMS_ENV) && `environments/${DATOCMS_ENV}`,
  DATOCMS_PREVIEW_ENABLED && 'preview',
]).join('/');

export const request = async <Type = never>({ query, variables }: RequestParams): Promise<Type> => {
  const response = await fetcher(API_ENDPOINT, {
    token: DATOCMS_TOKEN,
    body: {
      query,
      variables,
    },
  });

  if (response.errors) {
    captureException(new Error('Dato Query Error'), { extra: { errors: response.errors } });
  }

  return response.data;
};
