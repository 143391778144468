import { captureException } from '@/utils/sentry';
import { startCase } from 'lodash';
import type { AnalyticsPlugin } from 'analytics';

function isEventValid(event: string): boolean {
  return event === startCase(event);
}

const eventValidationPlugin: AnalyticsPlugin = {
  name: 'ract-event-validation',
  // eslint-disable-next-line consistent-return
  trackStart({ payload, abort }: { payload: { [key: string]: string }; abort: (message: string) => null }) {
    if (!isEventValid(payload.event)) {
      const errorMessage = `Invalid event name ${payload.event}`;
      captureException(new Error(errorMessage));
      return abort(errorMessage);
    }
  },
};

export { eventValidationPlugin };
