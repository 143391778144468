/* eslint-disable import/order, @typescript-eslint/ban-ts-comment */
// @ts-ignore
import Analytics from '@analytics/core';
// @ts-ignore
import { AnalyticsProvider, useAnalytics } from 'use-analytics';
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';
import type { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
import deferPlugin from 'analytics-plugin-defer';

import { eventValidationPlugin } from './event-validation-plugin';

const analytics = Analytics({
  app: 'ract-app',
  debug: process.env.NODE_ENV === 'development',
  plugins: [
    eventValidationPlugin,
    deferPlugin({ plugins: ['google-tag-manager'] }),
    googleTagManager({
      containerId: 'GTM-5877JRQ',
      enabled: false,
    }) as AnalyticsPlugin,
  ],
}) as AnalyticsInstance;

export { analytics, AnalyticsProvider, useAnalytics };
