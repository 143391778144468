/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs';

const isSentryEnabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';
const isDevBuild = process.env.NODE_ENV === 'development';

const shouldConsoleLogError = !isSentryEnabled || isDevBuild;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customCaptureException = (error: Error | any, captureContext?: Record<string, unknown>): void => {
  if (shouldConsoleLogError) {
    console.log('sentry:captureException', { captureContext: JSON.stringify(captureContext) });
    console.error(error?.stack);
  }

  Sentry.captureException(error, captureContext);
};

const customCaptureMessage = (message: string, captureContext?: Record<string, unknown>): void => {
  if (shouldConsoleLogError) {
    console.log('sentry:captureMessage', { message, captureContext });
  }

  Sentry.captureMessage(message, captureContext);
};

export * from '@sentry/nextjs';

export { customCaptureException as captureException };
export { customCaptureMessage as captureMessage };
