import { constant, times } from 'lodash';

import {
  ARTICLE_CARD_FRAGMENT,
  ARTICLE_FULL_FRAGMENT,
  ARTICLE_LINK_FRAGMENT,
  CONFIGURATION_FULL_FRAGMENT,
  HOME_PAGE_FULL_FRAGMENT,
  HOME_PAGE_LINK_FRAGMENT,
  JOURNEY_CARD_FRAGMENT,
  JOURNEY_FULL_FRAGMENT,
  JOURNEY_LANDING_PAGE_FULL_FRAGMENT,
  JOURNEY_LANDING_PAGE_LINK_FRAGMENT,
  JOURNEY_LINK_FRAGMENT,
  PAGE_FULL_FRAGMENT,
  PAGE_LINK_FRAGMENT,
  RELATED_ARTICLE_LANDING_PAGE_FRAGMENT,
  REWARD_FULL_FRAGMENT,
  REWARD_LINK_FRAGMENT,
  TAG_FULL_FRAGMENT,
  TAG_LINK_FRAGMENT,
} from '@/lib/datocms/fragments';
import { SLUGS } from '@/lib/constants';

const PAGE_DEPTH = 5;

const ROOT_PAGES_QUERY = `query RootPagesQuery($rootSlug: String) {
  allPages(filter: { parent: { eq: null }, slug: { eq: $rootSlug } }) {
    id
    slug
    title
    ${times(
      PAGE_DEPTH,
      constant(`children {
      id
      slug
      title`)
    ).join(' ')}
    ${times(PAGE_DEPTH, constant(`}`)).join(' ')}
  }
}`;

const TAG_QUERY = `query TagQuery($slug: String) {
  tag(filter: {slug: {eq: $slug }}) {
    id
  }
}`;

const PAGE_BY_ID_QUERY = `query PageByIdQuery($id: ItemId) {
  page(filter: {id: {eq: $id}}) {
    ${PAGE_FULL_FRAGMENT}
  }
}`;

const PAGE_LINK_BY_ID_QUERY = `query PageByIdQuery($id: ItemId) {
  page(filter: {id: {eq: $id}}) {
    ${PAGE_LINK_FRAGMENT}
  }
}`;

const ARTICLE_LANDING_PAGE_QUERY = `query ArticleLandingPageQuery($categoryPageId: ItemId, $first: IntType, $skip: IntType) {
  allArticlesMeta: _allArticlesMeta(filter: { categoryPage: { eq: $categoryPageId }}) {
    count
  }
  articles: allArticles(filter: { categoryPage: { eq: $categoryPageId }}, orderBy: _firstPublishedAt_DESC, first: $first, skip: $skip) {
    ${ARTICLE_CARD_FRAGMENT}
  }
  allPages(filter: {slug: {eq: "${SLUGS.RELATED_ARTICLES}"}}) {
    ${RELATED_ARTICLE_LANDING_PAGE_FRAGMENT}
  }
}`;

const ARTICLE_PAGE_QUERY = `query ArticlePageQuery($slug: String, $categoryPageId: ItemId) {
  article(filter: {slug: {eq: $slug}, categoryPage: {eq: $categoryPageId}}) {
    ${ARTICLE_FULL_FRAGMENT}
  }
}`;

const REWARD_PAGE_QUERY = `query RewardPageQuery($slug: String, $categoryPageId: ItemId) {
  reward(filter: {slug: {eq: $slug}, categoryPage: {eq: $categoryPageId}}) {
    ${REWARD_FULL_FRAGMENT}
  }
}`;

const JOURNEY_TAG_PAGE_QUERY = `query JourneyTagPageQuery($tagId: ItemId, $first: IntType, $skip: IntType) {
  tag(filter: {id: {eq: $tagId}}) {
    ${TAG_FULL_FRAGMENT}
  }
  allJourneysMeta: _allJourneysMeta(filter: {tag: {eq: $tagId}}) {
    count
  }
  journeys: allJourneys(filter: {tag: {eq: $tagId}}, orderBy: _firstPublishedAt_DESC, first: $first, skip: $skip) {
    ${JOURNEY_CARD_FRAGMENT}
  }
}`;

const JOURNEY_PAGE_QUERY = `query JourneyPageQuery($slug: String, $tagId: ItemId) {
  journey(filter: {slug: {eq: $slug}, tag: {eq: $tagId}}) {
    ${JOURNEY_FULL_FRAGMENT}
  }
}`;

const JOURNEY_LANDING_PAGE_QUERY = `query JourneyLandingPageQuery {
  journeyLandingPage {
    ${JOURNEY_LANDING_PAGE_FULL_FRAGMENT}
  }
}`;

const HOME_PAGE_QUERY = `query HomePageQuery {
  homePage {
    ${HOME_PAGE_FULL_FRAGMENT}
  }
}`;

const ALL_MODELS_QUERY = `
  query AllModelsQuery($first: IntType, $skip: IntType) {
    pages: allPages(first: $first, skip: $skip) {
      ${PAGE_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
    journeys: allJourneys(first: $first, skip: $skip) {
      ${JOURNEY_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
    articles: allArticles(first: $first, skip: $skip) {
      ${ARTICLE_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
    tags: allTags(first: $first, skip: $skip) {
      ${TAG_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
    rewards: allRewards(first: $first, skip: $skip) {
      ${REWARD_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
    homePage {
      ${HOME_PAGE_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
    journeyLandingPage {
      ${JOURNEY_LANDING_PAGE_LINK_FRAGMENT}
      updatedAt: _updatedAt
    }
  }
`;

const CONFIGURATION_QUERY = `
  query ConfigurationQuery {
    configuration {
      ${CONFIGURATION_FULL_FRAGMENT}
    }
  }
`;

export {
  ALL_MODELS_QUERY,
  ARTICLE_LANDING_PAGE_QUERY,
  ARTICLE_PAGE_QUERY,
  CONFIGURATION_QUERY,
  HOME_PAGE_QUERY,
  JOURNEY_LANDING_PAGE_QUERY,
  JOURNEY_PAGE_QUERY,
  JOURNEY_TAG_PAGE_QUERY,
  PAGE_BY_ID_QUERY,
  PAGE_LINK_BY_ID_QUERY,
  REWARD_PAGE_QUERY,
  ROOT_PAGES_QUERY,
  TAG_QUERY,
};
