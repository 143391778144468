import { createContext, useContext } from 'react';
import { Configuration } from '@/lib/types';

export const defaultConfigurationContext = {
  announcementContent: null,
  announcementStartTime: null,
  announcementEndTime: null,
};

const ConfigurationContext = createContext<Configuration | undefined>(defaultConfigurationContext);

const useConfigurationContext = (): Configuration | undefined => useContext(ConfigurationContext);

export { ConfigurationContext, useConfigurationContext };
